import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const Home = lazy(() => import("../Pages/Home"));
const About = lazy(() => import("../Pages/About"));
const SubsidiaryProjects = lazy(() => import("../Pages/SubsidiaryProjects"));

const Technologies = lazy(() => import("../Pages/Technologies"));
const Services = lazy(() => import("../Pages/Services"));
const Markets = lazy(() => import("../Pages/Markets"));
const News = lazy(() => import("../Pages/News"));
const NewsDetail = lazy(() => import("../Pages/NewsDetail"));
const Careers = lazy(() => import("../Pages/Careers"));
const Contact = lazy(() => import("../Pages/Contact"));
const Testimonials = lazy(() => import("../Pages/Testimonials"));
const PrivacyStatement = lazy(() => import("../Pages/PrivacyStatement"));
const ValueEngineering = lazy(() => import("../Pages/ValueEngineering"));

const MainRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/subsidiaryprojects" element={<SubsidiaryProjects />} />
        <Route path="/markets" element={<Markets />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/careers" element={<Careers />} />
        {/* <Route path="/careers/:id" element={<CareersDetail />} /> */}
        <Route path="/careers/testimonials" element={<Testimonials />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/demo" element={<Demo />} /> */}
        <Route path="/privacy-statement" element={<PrivacyStatement />} />
        <Route path="/value-engineering" element={<ValueEngineering />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
